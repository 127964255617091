import { MarketDataArray } from "../../models/ProductMarketData";
import { FactoryServiceInterface } from "../../../../definitions";
import { applyIfDefined, fromSerializedString } from "../../../../utils";

export type Source = string
export type Return = MarketDataArray

/**
 * Factory service Product Market from string data.
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 */
export class ProductMarketDataArrayFactoryServiceStringImplementation
    implements FactoryServiceInterface<Source, Return> {

    /**
     * Parse/create market data array  from input data
     *
     * @author Nick Odumo <nick@sharkbyte.ca>
     * @param marketDataArrayAsString Market data to be parsed.
     */
    create(marketDataArrayAsString: Source): Return {
        try {
            return JSON.parse(
                applyIfDefined(marketDataArrayAsString, fromSerializedString)
            );
        } catch (error) {
            alert(
                `Error: Cannot parse the provided data provided in the the URL (${String(error)}).`
            );
            console.error(
                "ProductMarketDataArrayFactoryServiceStringImplementation::create()",
                error,
                marketDataArrayAsString,
                console.trace()
            );
            return [];
        }
    }
}

export const productMarketDataArrayFactoryServiceString: ProductMarketDataArrayFactoryServiceStringImplementation = 
    new ProductMarketDataArrayFactoryServiceStringImplementation();
