import { max, min } from "lodash";
import { MarketData, MarketDataArray, } from "../domain/market/models/ProductMarketData";
import { arrayWhereMatch, isDefinedOrDefault, NumberOrUndefined } from "../utils";
import { MarketDataTableCustomizations } from "./marketdatatable/types";
import { MarketDataPlotCustomizations, MarketPlotComputedRenderProps } from "./types";

/**
   * The call signature for `OnLeave`. 
   * Basing my logic on.
   * 1) [Large View ]  Table showing] Neet to fit the largest "square"
   * 2) [Large View ]  Table not-showing] Plot cannot just take the width. You get a *    square too big for viewport. Need to set a maximum size.
   * 3) [ Mobile view ] Show the largest possible square for the viewport.
   * @param height - true if successful
   * @param width - Width of the container 
   */
export const getPlotDimensions = (height: number, width: number): number => {
    if (width > 889) {
        return window.innerHeight * 0.85;
    } else {
        return width;
    }
};


/**
 * Calculate range of Y Axis.
 * 
 * TASK 707: Change Y axis min and max
 *
 * "Currently the 2D y-axis is +/- the same value with 0 at the midpoint, which is not general enough.
 *  To make it more adaptable please revise it as follows:
 *  Min = lowest growth rate (%) minus 5%, then rounded down to nearest even integer
 *  Max = highest growth rate (%) plus 5%, then rounded up to nearest even integer
 *  This should ensure that the mid-point is an integer, and therefore easily labelled. " - Ian 
 * 
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @param marketPlotComputedRenderProps Computed props
 */
export const calcRangeOfYAxis = (marketPlotComputedRenderProps: MarketPlotComputedRenderProps): number[] => {
    const calculateCeiling =
        (value: number) => 2 * Math.round((value + 5) / 2)
    const calculateFloor =
        (value: number) => 2 * Math.round((value - 5) / 2)
    const maxY = marketPlotComputedRenderProps.largestGrowthRate === undefined ? 0 : calculateCeiling(marketPlotComputedRenderProps.largestGrowthRate);
    const minY = marketPlotComputedRenderProps.smallestGrowthRate === undefined ? 0 : calculateFloor(marketPlotComputedRenderProps.smallestGrowthRate);
    return [minY, maxY];
};

/**
 * Calculates the product's share of its market.
 *
 * @remarks
 * Added initially for use with the 2D/3D market plot.
 * With the intent of calculating th size of the spheres and circles
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @param productMarketData Product marketdata.
 */
const calcShareOfMarketSize = function (productMarketData: MarketData): number {
    return (productMarketData.marketShare / 100) * productMarketData.marketSize;
};
/**
 * Calculates the percent of viewport height fot entity to occupy.
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @param heightOfWindow Current percent of Window height
 */
export const calcPlotPercentOfViewportSize = (heightOfWindow: number) => heightOfWindow * 0.75

/**
 * Calculates the largest product share of the market.
 *
 * @remarks
 * Added to support the need for manually calculating the manual scaling of entities
 * dependent on the share of the market size
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @param productMarketDataSet Product market data set
 */
export const calcLargestShareOfMarketSize = function (
    productMarketDataSet: MarketDataArray
): number | undefined {
    return max(productMarketDataSet.map(calcShareOfMarketSize));
};

/**
 * Calculates the largest growth rate of the market data.
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @param productMarketDataSet Product market data set
 */
export const calcLargestGrowthRate = function (
    productMarketDataSet: MarketDataArray
): number | undefined {
    return max(productMarketDataSet.map((marketData: MarketData) => marketData.growthRate));
};

/**
 * Calculates the smallest growth rate of the market data.
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @param productMarketDataSet Product market data set
 */
export const calcSmallestGrowthRate = function (
    productMarketDataSet: MarketDataArray
): number | undefined {
    return min(productMarketDataSet.map((marketData: MarketData) => marketData.growthRate));
};

/**
 * Calculates the largest product share of the market.
 *
 * @remarks
 * Added to support the need for manually calculating the manual scaling of entities
 * dependent on the share of the market size
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @param largestShareOfMarketSizeOrUndefined Product market data set
 * @param largestSphere Largest sphere size
 * @param productMarketData Product market data for a singe product
 */
export const calcAdjustedSizeOfSphere = function (
    largestShareOfMarketSizeOrUndefined: NumberOrUndefined,
    largestSphere: number,
    productMarketData: MarketData
): number {
    const largestShareOfMarketSize: number = isDefinedOrDefault(largestShareOfMarketSizeOrUndefined, 1),
    shareOfMarketSizeThisProduct = calcShareOfMarketSize(productMarketData);

    return Math.sqrt(shareOfMarketSizeThisProduct / largestShareOfMarketSize) * largestSphere;
};


/**
 * Calculate market plot overrides.
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @param marketDataTableCustomizations Market data table customizations
 */
export const calcMarketPlotOverrides = function (
    marketDataTableCustomizations: MarketDataTableCustomizations
): MarketDataPlotCustomizations {
    const getWhereColumnFieldId = (columnId: string) => {
        return arrayWhereMatch(marketDataTableCustomizations.columnOverrides, {
            columnFieldId: columnId,
        });
    };
    return {
        sustainabilityColumnLabel: getWhereColumnFieldId(
            "sustainabilityRating"
        ),
        marketSizeColumnLabel: getWhereColumnFieldId("marketSize"),
        growthRateColumnLabel: getWhereColumnFieldId("growthRate")
    };
};



