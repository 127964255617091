import React from "react";
import {TableContext} from "../../contexts/TableContext";

/**
 * Ugly provider implementation.
 *
 * @description No other mechanism I can think of to get around this issue.
 */
export default class TableHeaderProvider extends React.Component {

    state = {
        headerId: '',
        setHeaderId: (string: string) => {
            // @ts-ignore
            this.changeName(string);
            // @ts-ignore
            this.state.headerId = string;
        }
    }

    changeName = name => this.setState({name})

    onChange = event => this.setState({[event.target.name]: event.target.value})

    render() {
        return (
            <TableContext.Provider value={{...this.state, ...this}}>
                {this.props.children}
            </TableContext.Provider>
        )
    }
}