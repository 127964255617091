import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "./internal.scss";
import {AgGridReact} from "ag-grid-react";
import * as React from "react";
import {useRef} from "react";
import {useCurrentDimensionOfRef} from "../../hooks/useCurrentDimensionOfRef";
import {useCurrentWidthOfWindow} from "../../hooks/useCurrentWidthOfWindow";
import {calcColumnDefinition, calcDefaultColumnDefinition, gridOptions} from "./internal";
import TableHeaderProvider from "./TableHeaderProvider";
import {MarketDataTableProps} from "./types";
import {useViewportSettings} from "../../hooks/useViewportSettings";

/**
 * Market data table.
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @description Data table for market graph.
 * @param props Props
 */
export default function MarketDataTable(props: MarketDataTableProps) {
    const componentRef = useRef<HTMLInputElement | null>(null),
        viewportSettings= useViewportSettings(),
        dimensionsOfTableContainer  = useCurrentDimensionOfRef(componentRef),
        dimensionsOfWindow = useCurrentWidthOfWindow();
    return (
        <div
            ref={componentRef}
            className="ag-theme-balham"
            style={{
                height: `${dimensionsOfWindow.height * 0.45}px`,
                width: "100%",
            }}
        >
            <TableHeaderProvider>
                <AgGridReact
                    defaultColDef={calcDefaultColumnDefinition()}
                    columnDefs={calcColumnDefinition(
                        dimensionsOfTableContainer,
                        viewportSettings,
                        props.marketDataTableCustomizations,
                        props.onHeaderOverrideDefined
                    )}
                    gridOptions={gridOptions}
                    onCellClicked={props.onSelectedRowIndexChanged}
                    onCellEditingStopped={props.onStopRowFocus}
                    onCellValueChanged={props.onRowValueChanged}
                    rowData={props.data}
                />
            </TableHeaderProvider>
        </div>
    );
}
