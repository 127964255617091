import {isTouchDevice, ViewportSettings} from "../utils";
import {useEffect, useState} from "react";

const getSettings = () => {
    return {
        isTouchDevice: isTouchDevice()
    }
}

export const useViewportSettings = function (): ViewportSettings {
    let [settings, setSettings] = useState(getSettings());

    useEffect(() => {
        let timeoutId:  NodeJS.Timeout | null = null;
        const resizeListener = () => {
            if(timeoutId){
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                setSettings(getSettings())
            }, 150);
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, [])

    return settings;
}