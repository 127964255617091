import {Either, left, right} from "fp-ts/lib/Either";
import {ValidatorInterface} from "../../../../definitions";
import {MarketData} from "../../models/ProductMarketData";
import {SUSTAINABILITY_MAX, SUSTAINABILITY_MIN} from "../../models/Constants";
import {
    LABEL_MARKET_SHARE,
    LABEL_MARKET_SHARE_COMPETITOR,
    LABEL_MARKET_SIZE,
    LABEL_PRODUCT_NAME,
    LABEL_SUSTAINABILITY,
    MATH_PERCENT_MAX,
    MATH_PERCENT_MIN,
} from "../../../../constants";

/**
 * Validate product market data.
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @see https://iibd.visualstudio.com/HostedPortfolio/_workitems/edit/654
 */
export class ValidateProductMarketDataServiceInterpreter
    implements ValidatorInterface<MarketData, Either<string, MarketData>> {

    validate(rawValueObject: MarketData): Either<string, MarketData> {
        try {
            const sumOfMarketShareAndCompetitors = rawValueObject.marketShare + rawValueObject.marketShareCompetitor,
                errorProductNameUndefined = left(`Error: ${LABEL_PRODUCT_NAME} (${rawValueObject.productName}) should be defined.`),
                errorMarketSizeMinimum = left(`Error: ${LABEL_MARKET_SIZE}  should be greater than zero.`),
                errorSustainabilityIssue = left(
                    `Error: ${LABEL_SUSTAINABILITY} rating should be between ${SUSTAINABILITY_MIN} and ${SUSTAINABILITY_MAX}. Received ${rawValueObject.sustainabilityRating}.`
                ),
                errorMarketShareIssue = left(
                    `Error: ${LABEL_MARKET_SHARE} and ${LABEL_MARKET_SHARE_COMPETITOR} rating should be between ${MATH_PERCENT_MIN} and ${MATH_PERCENT_MAX}.`
                ),
                errorMarketShareSum = left(
                    `Error: Cumulative percentage of ${LABEL_MARKET_SHARE} and ${LABEL_MARKET_SHARE_COMPETITOR} in this case, ${sumOfMarketShareAndCompetitors} should not exceed ${MATH_PERCENT_MAX}.`
                );
            if (rawValueObject.productName.length === 0) {
                return errorProductNameUndefined;
            } else if (rawValueObject.marketSize < 0) {
                return errorMarketSizeMinimum;
            } else if (
                rawValueObject.sustainabilityRating > SUSTAINABILITY_MAX ||
                rawValueObject.sustainabilityRating < SUSTAINABILITY_MIN
            ) {
                return errorSustainabilityIssue;
            } else if (
                rawValueObject.marketShare > MATH_PERCENT_MAX ||
                rawValueObject.marketShare < MATH_PERCENT_MIN ||
                rawValueObject.marketShareCompetitor > MATH_PERCENT_MAX ||
                rawValueObject.marketShareCompetitor < MATH_PERCENT_MIN
            ) {
                return errorMarketShareIssue;
            } else if (
                sumOfMarketShareAndCompetitors >
                MATH_PERCENT_MAX
            ) {
                return errorMarketShareSum;
            }
            return right(rawValueObject);
        } catch (e) {
            return left(e);
        }
    }
}

export const validateProductMarketDataServiceInterpreter: ValidateProductMarketDataServiceInterpreter =
    new ValidateProductMarketDataServiceInterpreter();
