import { ReactStateDeclaration, Transition } from "@uirouter/react";
import MarketSegmentModule from "../pages/marketsegmentmodule/MarketSegmentModule";
import { applyIfDefined } from "../utils";
import { STATE_ROOT, STATE_MARKET_SEGMENT_MODULE } from "../constants";

/**
 * Application states.
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @see https://ui-router.github.io/guide/transitions
 */
export const states: Array<ReactStateDeclaration> = [
    {
        name: STATE_ROOT,
        url: "",
        abstract: false,
        component: MarketSegmentModule,
        redirectTo: () => {
            return {
                state: STATE_MARKET_SEGMENT_MODULE,
                params: {
                    marketsegmentdata: undefined,
                    isembedded: false,
                    tablestate: undefined,
                },
            };
        },
    },
    {
        name: STATE_MARKET_SEGMENT_MODULE,
        url: `/${STATE_MARKET_SEGMENT_MODULE}?{marketsegmentdata}{isembedded}{tablestate}`,
        abstract: false,
        component: MarketSegmentModule,
        params: {
            isembedded: {
                type: "string",
                value: "false",
                squash: true,
            },
            marketsegmentdata: {
                type: "string",
                value: undefined,
                squash: true,
            },
            tablestate: {
                type: "string",
                value: undefined,
                squash: true,
            },
        },
        resolve: [
            {
                token: "navigationcontext",
                deps: ["$transition$"], 
                /**
                 * Initilialize the market-segment module.
                 * 
                 * @author Nick Odumo <nick@sharkbyte.ca>
                 * @param transition UI-Router transition
                 */
                resolveFn: (transition: Transition) => {
                    try {
                        const params = transition.params()
                        return {
                            isEmbedded: params.isembedded === "true",
                            serializedMarketSegmentData: applyIfDefined(
                                params.marketsegmentdata,
                                decodeURIComponent
                            ),
                            serializedDataTableState: applyIfDefined(
                                params.tablestate,
                                decodeURIComponent
                            ),
                        };
                    } catch (error) { 
                        console.error(
                            `${STATE_MARKET_SEGMENT_MODULE}::resolveFn() error:`,
                            error
                        );
                    }
                },
            },
        ],
    },
];
