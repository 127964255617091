import { LABEL_BCG_PLOT_TITLE, LABEL_GROWTH_RATE, LABEL_MARKET_SHARE_RELATIVE } from "../../constants";
import { MarketData } from "../../domain/market/models/ProductMarketData";
import { Dimensions, getColor } from "../../utils";
import { calcAdjustedSizeOfSphere, calcRangeOfYAxis, getPlotDimensions } from "../internal";
import { MarketDataPlotCustomizations, MarketPlotComputedRenderProps } from "../types";
import { range } from "lodash";

// --- Constants

const MAX_SPHERE_SIZE = 130;


/**
 * Compute layout for 2D plot.
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @see https://iibd.visualstudio.com/HostedPortfolio/_workitems/edit/657/
 * @param dimensions Dimensions of the surface
 * @param dimensionsWindow Dimensions window
 * @param marketDataCustomizations Market data plot customizations
 * @param marketPlotComputedRenderProps Render properties
 */
export const compPlotlyLayout = (
    dimensionsContainer: Dimensions,
    marketDataCustomizations: MarketDataPlotCustomizations,
    marketPlotComputedRenderProps: MarketPlotComputedRenderProps
) => {
    const yCalculatedRange = calcRangeOfYAxis(marketPlotComputedRenderProps);
    const yMax = yCalculatedRange[1];
    const yMin = yCalculatedRange[0];
    const yTicks = range(yMin, yMax, 5);
    const dimensions = getPlotDimensions(dimensionsContainer.height, dimensionsContainer.width);
    return {
        title: LABEL_BCG_PLOT_TITLE,
        height: dimensions,
        width: dimensions,
        opacity: 0.5,
        legend: {
            y: 1,
            itemsizing: "constant"
        },
        scene: {
            autosize: false,
            dimension: "ratio",
            aspectmode: 'manual',
            aspectratio: {
                x: 1,
                y: 1,
                z: 1,
            },
            dragmode: "turntable",
            xaxis: {
                type: "log",
                title: { text: LABEL_MARKET_SHARE_RELATIVE },

            },
        },
        showlegend: true,
        yaxis: {
            zerolinewidth: 2,
            title: {
                text: marketDataCustomizations.growthRateColumnLabel?.columnHeaderLabel
                    ? marketDataCustomizations.growthRateColumnLabel.columnHeaderLabel
                    : LABEL_GROWTH_RATE,
            },
            tickvals: yTicks,
            range: [yMin, yMax]
        },

        marker: {
            opacity: 0.9,
        },
        xaxis: {
            type: "log",
            autoreversed: true,
            fixedrange: true,
            mirror: true,
            showline: true,
            order: "ascending",
            rangemode: "tozero",
            ticklen: 5,
            tickvals: [10, 1, 0.1],
            title: {
                text: LABEL_MARKET_SHARE_RELATIVE,
            },
            zeroline: true,
            zerolinewidth: 2,
            nticks: 3,
            range: [1, -1],
        },
        annotations: [],
    };
};

// --- Functions

const sizeLens = function (
    market2DComputedRenderProps: MarketPlotComputedRenderProps,
    productMarketData: MarketData
): number {
    return calcAdjustedSizeOfSphere(
        market2DComputedRenderProps.largestShareOfMarketSize,
        MAX_SPHERE_SIZE,
        productMarketData
    );
};

const nameLens = function (productMarketData: MarketData): string {
    return productMarketData.productName;
};

const xLens = function (productMarketData: MarketData): number[] {
    const mySize = productMarketData.marketShare / productMarketData.marketSize;
    const competitorSize =
        productMarketData.marketShareCompetitor / productMarketData.marketSize;
    return [mySize / competitorSize];
};

const yLens = function (productMarketData: MarketData): number[] {
    return [productMarketData.growthRate];
};

export const toPlotlyMarker = function (
    market2DComputedRenderProps: MarketPlotComputedRenderProps,
    productMarketData: MarketData
): any {
    return {
        name: nameLens(productMarketData),
        x: xLens(productMarketData),
        y: yLens(productMarketData),
        text: "",
        hovermode: "closest",
        hoverlabel: {
            bgcolor: "#FFF",
        },
        hovertemplate: `${LABEL_MARKET_SHARE_RELATIVE}: %{x} <br>${LABEL_GROWTH_RATE}: %{y}`,
        mode: "markers",
        marker: {
            color: getColor(productMarketData.productName),
            size: sizeLens(market2DComputedRenderProps, productMarketData),
            opacity: 0.5
        },
    };
};

export const productMarketDataToMarker = function (
    market2DComputedRenderProps: MarketPlotComputedRenderProps
) {
    return (productMarketData: MarketData) =>
        toPlotlyMarker(market2DComputedRenderProps, productMarketData);
};
