import "./internal.scss";
import {
    CellValueChangedEvent,
    CellClickedEvent,
} from "ag-grid-community";
import * as React from "react";
import {  isLeft } from "fp-ts/lib/Either";
import { concat, isNumber } from "lodash";
import { useState } from "react";
import { Alert, Row, Col } from "react-bootstrap";
import CopyText from "../../components/copytext/CopyText";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import MarketDataTable from "../../components/marketdatatable";
import {
    initMarketDataTableCustomizations,
    updateMarketDataTableCustomizations,
} from "../../components/marketdatatable/api";
import Market2DPlot from "../../components/market2dplot";
import Market3DPlot from "../../components/market3dplot";
import NavigationBar from "../../components/navigationbar/NavigationBar";
import NavigationFooter from "../../components/applicationfooter/ApplicationFooter";
import MarketDataTableAddRemove from "../../components/marketdatatablecontextbuttons";
import MarketDatatableItemCount from "../../components/marketdatatableitemcount";
import { HeaderOverrideDefinedEvent } from "../../components/marketdatatablecolumnheader/types";
import { MarketDataTableCustomizations } from "../../components/marketdatatable/types";
import { STATE_MARKET_SEGMENT_MODULE } from "../../constants";
import { productMarketDataArrayFactoryServiceString } from "../../domain/market/services/implementations/ProductMarketDataArrayFactoryServiceStringImplementation";
import { productMarketDataArrayFactoryServiceConstant } from "../../domain/market/services/implementations/ProductMarketDataArrayFactoryServiceConstantImplementation";
import { productMarketDataFactoryServiceConstant } from "../../domain/market/services/implementations/ProductMarketDataFactoryServiceConstantImplementation";
import { productMarketDataFactoryServiceDictionary } from "../../domain/market/services/implementations/ProductMarketDataFactoryServiceDictionaryImplementation";
import { validateProductMarketDataServiceInterpreter } from "../../domain/market/services/implementations/ValidateProductMarketDataServiceInterpreter";
import {
    arrayReplaceAtIndex,
    jsonToSerializedString,
    jsonToUrlEncodedSerializedString,
    jsonFromSerializedString,
    arrayDropAtIndex,
    calcIsClassApplied,
} from "../../utils";
import { MarketSegmentModuleRouteProps } from "./types";
import { TABLE_TYPES } from "./internal";

export default function MarketSegmentModule(props: any) {
    const navigationContextParameters: MarketSegmentModuleRouteProps = props.navigationcontext;

    const [iSidebarOpen, setISidebarOpen] = useState<boolean> (true);

    const [iEmbedded, setEmbedded] = useState(navigationContextParameters.isEmbedded);

    const [marketSegmentDataArray, setMarketSegmentData] = useState(
        navigationContextParameters.serializedMarketSegmentData
            ? productMarketDataArrayFactoryServiceString.create(
                  navigationContextParameters.serializedMarketSegmentData
              )
            : productMarketDataArrayFactoryServiceConstant.create()
    );

    const [
        marketSegmentDataTableSettings,
        setMarketSegmentDataTableSettings,
    ] = useState<MarketDataTableCustomizations>(
        initMarketDataTableCustomizations(
            navigationContextParameters.serializedDataTableState
                ? jsonFromSerializedString(
                      navigationContextParameters.serializedDataTableState
                  )
                : undefined
        )
    );

    const [chartTypeCode, setChartTypeCode] = useState(TABLE_TYPES.PLOT_2D);

    const [selectedTableRowIndex, setSelectedTableRowIndex] = useState<number | null>(null);

    const toggSideBar = () => {
        setISidebarOpen(!iSidebarOpen)
    }

    // eslint-disable-next-line
    const doStateChangeToChart = () => {
        props.transition.router.stateService.go(STATE_MARKET_SEGMENT_MODULE, {
            isembedded: navigationContextParameters.isEmbedded,
            marketsegmentdata: jsonToSerializedString(
                marketSegmentDataArray
            ),
            tablestate: jsonToSerializedString(
                marketSegmentDataTableSettings
            ),
        });
    }

    const doHandleAddDefaultProductMarketDataItem = () => {
        const newArrayData = concat(marketSegmentDataArray, [
            productMarketDataFactoryServiceConstant.create(),
        ]);
        setMarketSegmentData(newArrayData);
    }

    const tableUpdateMarketDataTableCustomizations = function (
            event: HeaderOverrideDefinedEvent
    ) {
        setMarketSegmentDataTableSettings((settings) => {
            return updateMarketDataTableCustomizations(settings)(event);
        });
    }

    const tableOnSelectedRowIndexChanged = (event: CellClickedEvent) => {
        setSelectedTableRowIndex(event.rowIndex);
    }

    const tableOnSelectedRowStopped = () => {
        setSelectedTableRowIndex(null);
    }

    const tableOnRowValueChanged = (event: CellValueChangedEvent) => {
        const newMarketDataArray = arrayReplaceAtIndex(
            marketSegmentDataArray,
            event.rowIndex === null ? 0 : event.rowIndex,
            productMarketDataFactoryServiceDictionary.create(event.data)
        );
        setMarketSegmentData(newMarketDataArray);
    }

    const is3DChartActive = TABLE_TYPES.PLOT_3D === chartTypeCode

    const chartLink = `${
            window.location.origin
        }/#/${STATE_MARKET_SEGMENT_MODULE}?isembedded=${
            iEmbedded
        }&tablestate=${jsonToUrlEncodedSerializedString(
            marketSegmentDataTableSettings
        )}&marketsegmentdata=${jsonToUrlEncodedSerializedString(
            marketSegmentDataArray
        )}`

    const getErrorMessageElem = () => {
        var errorsArray: string[] = [];
        const errorsOrValue = marketSegmentDataArray.map(
                validateProductMarketDataServiceInterpreter.validate
            ),
            formatErrorMessage = (e) => {
                return <li key={e}>{e}</li>;
            },
            forEachErrorsOrValidatedValue = (stringOrObject, index) => {
                if (isLeft(stringOrObject)) {
                    errorsArray.push(`(Row ${index}) ${stringOrObject.left}`);
                }
            };
        errorsOrValue.forEach(forEachErrorsOrValidatedValue);
        return errorsArray.length > 0
            ? errorsArray.map(formatErrorMessage)
            : null;
    }

    const errorsListElem = getErrorMessageElem();

    return (
    <>
        { navigationContextParameters.isEmbedded ? null : <NavigationBar  />}
        <button className={"hidden-md-down droor-toggle " + (iSidebarOpen ? " open-toggle " : " ") + (navigationContextParameters.isEmbedded ? 'd-none' : '')  }
         onClick={() => toggSideBar()}> {iSidebarOpen ? <i className="fa fa-chevron-left "></i> : <i className="fa fa-chevron-right "></i> }</button>

        <div className={"app-container container"} >
            <div className={navigationContextParameters.isEmbedded ? "" : "pl-2 pt-2"}     >
                <Row>
                    <Col
                        sm={12}
                        md={6}
                        className={ "order-sm-2 order-md-1 " + (iSidebarOpen  && !navigationContextParameters.isEmbedded ? "sidebar open table-side" : "d-none") }
                    >
                        <Alert
                            key={"alert"}
                            variant={"danger"}
                            className={(errorsListElem ? "" : "d-none") + '  mt-3'}
                        >
                            <div>
                                <b>Validation Errors</b>
                            </div>
                            <ul>{errorsListElem}</ul>
                        </Alert>

                        <MarketDataTable
                            data={marketSegmentDataArray}
                            onTableHeaderChange={
                                tableUpdateMarketDataTableCustomizations
                            }
                            onHeaderOverrideDefined={
                                tableUpdateMarketDataTableCustomizations
                            }
                            marketDataTableCustomizations={
                                marketSegmentDataTableSettings
                            }
                            onSelectedRowIndexChanged={
                                tableOnSelectedRowIndexChanged
                            }
                            onRowValueChanged={tableOnRowValueChanged}
                            onStopRowFocus={tableOnSelectedRowStopped}
                        />
                        <Row>
                            <Col sm={6}>
                                <MarketDataTableAddRemove
                                    isDeleteEnabled={isNumber(
                                        selectedTableRowIndex
                                    )}
                                    onAddButtonClicked={
                                        doHandleAddDefaultProductMarketDataItem
                                    }
                                    onRemoveButtonClicked={() => {
                                        setMarketSegmentData(
                                            arrayDropAtIndex(
                                                marketSegmentDataArray,
                                                selectedTableRowIndex
                                            )
                                        );
                                        tableOnSelectedRowStopped();
                                    }}
                                />
                            </Col>
                            <Col sm={6} className="text-right mt-2">
                                <MarketDatatableItemCount
                                    count={marketSegmentDataArray.length}
                                />
                            </Col>
                        </Row>
                        <hr />
                        <CopyText className="pt-1" text={chartLink} isChecked={iEmbedded} iEmbedded={() => setEmbedded((x) => !x)}  />
                    </Col>



                    <Col id="marketPlotSection"
                         sm={12} md={navigationContextParameters.isEmbedded || !iSidebarOpen  ? 12 : 6}
                         className={ "order-sm-1 order-md-2  text-right mt-2 chart-content " +  (iSidebarOpen ? " open " : " ") }
                         >
                        <div  >
                            <Col className="mb-2" sm={6} >
                                <ToggleButtonGroup
                                    className="toggle"
                                    name="chart-toggle"
                                    type="radio"
                                    value={chartTypeCode}
                                    onChange={setChartTypeCode}
                                >
                                    <ToggleButton variant="light"  value={TABLE_TYPES.PLOT_2D}>
                                        2D
                                    </ToggleButton>
                                    <ToggleButton variant="light" value={TABLE_TYPES.PLOT_3D}>
                                        3D
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Col>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <div>
                                        <Market3DPlot
                                                data={marketSegmentDataArray}
                                                className={
                                                    calcIsClassApplied(!is3DChartActive, "d-none")
                                                }
                                                marketDataTableCustomizations={
                                                    marketSegmentDataTableSettings
                                                }
                                            />
                                    </div>
                                    <div>
                                        <Market2DPlot
                                            data={marketSegmentDataArray}
                                            className={
                                                calcIsClassApplied(is3DChartActive, "d-none")
                                            }
                                            marketDataTableCustomizations={
                                                marketSegmentDataTableSettings
                                            }
                                        />
                                     </div>
                                </div>
                            </div>
                            {   navigationContextParameters.isEmbedded ?
                                <div className="mt-2 plot-footer mb-3">
                                    Learn more at <a href="https://www.iibd.com">iibd.com</a>
                                    and <a href="https://www.sabresim.com">sabresim.com</a>.
                                </div> :
                                null
                            }
                        </div>
                    </Col>
                </Row>
            </div>
        </div>

        { navigationContextParameters.isEmbedded ? null : <NavigationFooter/> }
    </>
    );
}
