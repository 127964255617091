import { FactoryServiceConstantInterface } from "../../../../definitions";
import { MarketDataArray } from "../../models/ProductMarketData";

/**
 * Factory service Product Market Data Sequence default data.
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 */
export class ProductMarketDataArrayFactoryServiceConstantImplementation
    implements FactoryServiceConstantInterface<MarketDataArray> {

    private productMarketDataSampleSet: MarketDataArray = [
        {
            productName: "Product 1",
            marketSize: 650.0,
            growthRate: 5.0,
            marketShare: 34,
            marketShareCompetitor: 6.0,
            sustainabilityRating: 4.5,
        },
        {
            productName: "Product 2",
            marketSize: 480,
            growthRate: 20.0,
            marketShare: 19.0,
            marketShareCompetitor: 13.0,
            sustainabilityRating: 3.5,
        },
        {
            productName: "Product 3",
            marketSize: 820.0,
            growthRate: -2.0,
            marketShare: 15,
            marketShareCompetitor: 33.0,
            sustainabilityRating: 7.5,
        },
    ];

    create(): MarketDataArray {
        return this.productMarketDataSampleSet;
    }
}

export const productMarketDataArrayFactoryServiceConstant = new ProductMarketDataArrayFactoryServiceConstantImplementation();
