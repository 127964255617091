import * as React from "react";
import { isNumber } from "lodash";
import {
    LABEL_GROWTH_RATE,
    LABEL_PRODUCT_NAME,
    LABEL_MARKET_SIZE,
    LABEL_MARKET_SHARE_COMPETITOR,
    LABEL_MARKET_SHARE,
    LABEL_SUSTAINABILITY,
    MATH_PERCENT_MIN,
    MATH_PERCENT_MAX
} from "../../constants";
import MarketDataTableColumnHeader from "../marketdatatablecolumnheader/MarketDataTableColumnHeader";
import { HeaderOverrideDefinedEvent } from "../marketdatatablecolumnheader/types";
import ReactNumberCellEditor from "./NumberCellEditor";
import { MarketDataTableCustomizations } from "./types";
import {Dimensions, ViewportSettings} from "../../utils";


/**
 * Number parser logic.
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @private marketdatatable
 * @param value Number parser
 */ 
const sharedNumberParser =  (value: any) => {
    const default_number = 1,
        alertInvalidInput = function (input: any) {
        return alert(`Invalid input ${String(input)}. Expecting number.`);
    };
    if (value === null || value === undefined || value === "") {
        alertInvalidInput(value);
        return default_number;
    } else {
        const parsed = parseFloat(value);
        if (!isNaN(parsed)) {
            return parsed;
        } else {
            alertInvalidInput(parsed);
            return default_number;
        }
    }
};

/**
 * Number parser.
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @private marketdatatable
 * @param params Any value to parse a number from
 */ 
const numberParser = (params: any) => {
    return sharedNumberParser(params.newValue);
};

/**
 * Calculate default column definition.
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @private marketdatatable
 */ 
export const calcDefaultColumnDefinition = () => {
    return {
        width: 100,
        editable: true,
    };
};

export const gridOptions  = {
    suppressPropertyNamesCheck: true,
    suppressMovableColumns: true
}

/**
 * Calculate column definition.
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @private marketdatatable
 * @see https://www.ag-grid.com/javascript-grid-properties
 * @param dimensions Dimensions
 * @param viewportSettings Viewport settings
 * @param marketDataTableCustomizations Market data table customizations
 * @param onHeaderOverrideDefined On header override defined
 */ 
export const calcColumnDefinition = (
    dimensions: Dimensions,
    viewportSettings: ViewportSettings,
    marketDataTableCustomizations: MarketDataTableCustomizations,
    onHeaderOverrideDefined: (x: HeaderOverrideDefinedEvent) => void
) => {
    const calculatedWidth = dimensions.width / 6.1, 
        editableTableHeaderHeader = (props: any) => {
            return (
                <MarketDataTableColumnHeader
                    columnFieldId={props.column.colDef.field}
                    columnHeaderLabel={props.displayName}
                    onHeaderOverrideDefined={onHeaderOverrideDefined}
                    marketDataTableCustomizations={
                        marketDataTableCustomizations
                    }
                />
            );
        },
        numberCellRendererFramework = (props: any) => {
            const isInvalidNumber =
                isNaN(props.value) || !isNumber(props.value);
            return (
                <div className={isInvalidNumber ? "ag-error-cell" : ""}>
                    {" "}
                    {props.value}
                </div>
            );
        };

    return [
        {
            headerName: LABEL_PRODUCT_NAME,
            field: "productName",
            headerComponentFramework: editableTableHeaderHeader,
            lockPosition:true,
            singleClickEdit: viewportSettings.isTouchDevice,
            suppressSizeToFit: true,
            sortable: false,
            suppressMovable: true,
            editable: true, 
            width: calculatedWidth,
        },
        {
            headerName: LABEL_MARKET_SIZE,
            field: "marketSize",
            sortable: false,
            headerComponentFramework: editableTableHeaderHeader,
            cellEditorFramework: ReactNumberCellEditor,
            cellRendererFramework: numberCellRendererFramework,
            lockPosition:true,
            singleClickEdit: viewportSettings.isTouchDevice,
            suppressMovable: true,
            suppressSizeToFit: true,
            editable: true,
            type: ["numericColumn"],
            valueParser: numberParser,
            width: calculatedWidth,
            valueMin: 0
        },
        {
            headerName: LABEL_GROWTH_RATE,
            field: "growthRate",
            headerComponentFramework: editableTableHeaderHeader,
            cellEditorFramework: ReactNumberCellEditor,
            cellRendererFramework: numberCellRendererFramework,
            lockPosition:true,
            singleClickEdit: viewportSettings.isTouchDevice,
            suppressSizeToFit: true,
            sortable: false,
            editable: true,
            type: ["numericColumn"],
            valueParser: numberParser,
            width: calculatedWidth,
        },
        {
            headerName: LABEL_MARKET_SHARE,
            field: "marketShare",
            headerComponentFramework: editableTableHeaderHeader,
            cellEditorFramework: ReactNumberCellEditor,
            cellRendererFramework: numberCellRendererFramework,
            lockPosition:true,
            singleClickEdit: viewportSettings.isTouchDevice,
            suppressMovable: true,
            suppressSizeToFit: true,
            sortable: false,
            editable: true,
            type: ["numericColumn"],
            valueParser: numberParser,
            width: calculatedWidth,
            valueMin: 0,
            valueMax: 100,
        },
        {
            headerName: LABEL_MARKET_SHARE_COMPETITOR,
            field: "marketShareCompetitor",
            headerComponentFramework: editableTableHeaderHeader,
            cellEditorFramework: ReactNumberCellEditor,
            cellRendererFramework: numberCellRendererFramework,
            singleClickEdit: viewportSettings.isTouchDevice,
            lockPosition:true,
            suppressMovable: true,
            suppressSizeToFit: true,
            editable: true,
            type: ["numericColumn"],
            valueParser: numberParser,
            width: calculatedWidth,
            valueMin: MATH_PERCENT_MIN,
            valueMax: MATH_PERCENT_MAX,
        },
        {
            headerName: LABEL_SUSTAINABILITY,
            field: "sustainabilityRating",
            headerComponentFramework: editableTableHeaderHeader,
            cellEditorFramework: ReactNumberCellEditor,
            cellRendererFramework: numberCellRendererFramework,
            lockPosition:true,
            singleClickEdit: viewportSettings.isTouchDevice,
            suppressMovable: true,
            suppressSizeToFit: true,
            sortable: false,
            editable: true,
            type: ["numericColumn"],
            valueParser: numberParser,
            width: calculatedWidth,
            valueMin: 0,
            valueMax: 10,
        },
    ];
};
