import * as React from "react";
import {useEffect, useMemo, useRef} from "react";
import Plot from "react-plotly.js";
import sizeMe from 'react-sizeme'
import {MarketDataArray} from "../../domain/market/models/ProductMarketData";
import {Dimensions, triggerResize} from "../../utils";
import {
    calcLargestGrowthRate,
    calcLargestShareOfMarketSize,
    calcMarketPlotOverrides,
    calcSmallestGrowthRate
} from "../internal";
import {MarketPlotComputedRenderProps} from "../types";
import {plotlyLayoutConfig, productMarketDataToMarker} from "./internal";
import { Market3DPlotProps} from "./types";

/**
 * Market plot 3D.
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @param props React props
 */
export const Market3DPlot = sizeMe({ monitorHeight: true, monitorWidth: true, refreshRate: 16})(function  (props: Market3DPlotProps) {
    const marketData: MarketDataArray = props.data,
        componentRef = useRef<HTMLInputElement | null>(null), 
        computedRenderProps = (): MarketPlotComputedRenderProps => {
            return {
                largestShareOfMarketSize: calcLargestShareOfMarketSize(
                    marketData
                ),
                largestGrowthRate: calcLargestGrowthRate(marketData),
                smallestGrowthRate: calcSmallestGrowthRate(marketData)
            };
        },
        market3DComputedRenderProps: MarketPlotComputedRenderProps = useMemo(
            computedRenderProps,
            [marketData]
        );
   
    useEffect(() => {
        triggerResize();
    }, []);
 
    return (
        <div id={'market-3d-plot'} ref={componentRef} className={props.className}>
            <Plot
                data={marketData.map(
                    productMarketDataToMarker(
                        props.size as Dimensions, 
                        market3DComputedRenderProps,
                        calcMarketPlotOverrides(props.marketDataTableCustomizations))
                )}
                layout={plotlyLayoutConfig( 
                    props.size as Dimensions,
                    calcMarketPlotOverrides(props.marketDataTableCustomizations),
                    market3DComputedRenderProps
                )}
            />
        </div>
    );
})

export default Market3DPlot;