import * as React from "react";

import Button from "react-bootstrap/Button";
import { MarketDataTableAddProps } from "./types";

/**
 * Market data table add and remove button.
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @param props MarketDataTableAddProps
 */
export default function MarketDataTableAddRemove(
    props: MarketDataTableAddProps
) {
    return (
        <div className="mt-2">
            <Button
                variant="success"
                size="sm"
                onClick={props.onAddButtonClicked}
            >
               Add <i className="fa fa-plus"></i> 
            </Button>
            <Button
                variant="danger"
                className="ml-1"
                size="sm"
                onClick={props.onRemoveButtonClicked}
                disabled={!props.isDeleteEnabled}
            >
              Delete <i className="fa fa-trash"></i>
            </Button> 
            <Button
                variant="danger"
                className="d-none"
                size="sm"
            >
                Undo 
            </Button>
        </div>
    );
}
