/**
 *  _   _           _           _ ____            _    __       _ _       
 * | | | | ___  ___| |_ ___  __| |  _ \ ___  _ __| |_ / _| ___ | (_) ___  
 * | |_| |/ _ \/ __| __/ _ \/ _` | |_) / _ \| '__| __| |_ / _ \| | |/ _ \ 
 * |  _  | (_) \__ \ ||  __/ (_| |  __/ (_) | |  | |_|  _| (_) | | | (_) |
 * |_| |_|\___/|___/\__\___|\__,_|_|   \___/|_|   \__|_|  \___/|_|_|\___/
 *  
 * IIDB business intelligence tooling  ©2020
 * @author Nick Odumo <nick@sharkbyte.ca>
 */
import React from "react";
import ReactDOM from "react-dom";
import { UIRouter, UIView } from "@uirouter/react";
import { APPLICATION_NAME } from "./constants";
import router from "./router";
import "./index.scss";

const App = () => {
    return (
        <div>
            <UIView />
        </div>
    );
};

ReactDOM.render(
    <UIRouter router={router}>
        <App />
    </UIRouter>,
    document.getElementById(APPLICATION_NAME)
);
