import {concat, isNumber, findIndex, isString} from "lodash";
import { Base64String } from "../definitions";

export type Dimensions = { width: number, height: number};

export type ViewportSettings = {isTouchDevice: boolean}

export type NumberOrUndefined = number | undefined;

export const isDefinedOrDefault =
        <T>(value : T | null | undefined, defaultValue:T): T =>  (value !== undefined) && (value !== null) ? value : defaultValue;

export const isStringDefinedAndNoneEmpty = (newValue: string) => isString(newValue) && newValue.length !== 0

export const isTouchDevice = () => {
    return 'ontouchstart' in window        // works on most browsers
        || 'onmsgesturechange' in window;  // works on IE10 with some false positives
}


export const triggerResize = () => {
    if (typeof (Event) === 'function') {
        // modern browsers
        window.dispatchEvent(new Event('resize'));
    } else {
        const resizeEvent = window.document.createEvent('UIEvents');
        // @ts-ignore
        resizeEvent.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(resizeEvent);
    }
}

export const applyIfDefined = function <a, b>(
    value: a,
    fun: (a: a) => b
): b | undefined {
    try {
        return value ? fun(value) : undefined;
    } catch (e) {
        return undefined;
    }
};

export const calcIsClassApplied = function <a>(
    condition: boolean,
    className: string
): string {
    return condition ? className : "";
};

export const arrayDropAtIndex = function <a>(
    arr: a[],
    index: number | null | undefined
): a[] {
    let newArray: a[] = [];
    if (!isNumber(index)) return arr;
    for (let i = 0; i < arr.length; i++) {
        if (i !== index) {
            newArray.push(arr[i]);
        }
    }
    return newArray;
};

export const arrayReplaceAtIndex = function <a>(
    originalArray: a[],
    index: number,
    valueToReplaceWith: a
) {
    let newArray: a[] = [];
    for (let i = 0; i < originalArray.length; i++) {
        if (i === index) {
            newArray.push(valueToReplaceWith);
        } else {
            newArray.push(originalArray[i]);
        }
    }
    return newArray;
};

export const arrayUpdateWhereMatch = function <a>(
    array: a[],
    obj: any,
    newObj: a
): a[] {
    const indexOf = findIndex(array, obj);
    if (indexOf !== -1) {
        return arrayReplaceAtIndex(array, indexOf, newObj);
    } else {
        return concat(array, newObj);
    }
};

export const arrayWhereMatch = function <a>(
    array: a[],
    obj: any
): a | undefined {
    return array[findIndex(array, obj)];
};

export const random_rgba = function (input: string | null | undefined): string {
    const stringToEncode  = input ?  input : '';
    for (
        var i = 0, hash = 0;
        i < stringToEncode.length;
        hash = stringToEncode.charCodeAt(i++) + ((hash << 5) - hash)
    );
    var color = Math.floor(
        Math.abs(((Math.sin(hash) * 10000) % 1) * 16777216)
    ).toString(16);
    return "#" + Array(6 - color.length + 1).join("0") + color;
};

export const getColor = function (colorCode: string): string {
    /* Set initial state to the IIBD tool defaults.
    const map = {
        "Product 1": "#e5e508",
        "Product 2": "#00c000",
        "Product 3": "#e80000",
        "Product 4": "#20761c",
        "Product 5": "#c10179",
        "Product 6": "#0000c0",
        "Product 7": "#dadada",
        "Product 8": "#7e4f00",
        "Product 9": "#06dede",
        "Product 10": "#f30b9e",
    } ;return map[colorCode] || random_rgba(colorCode); */
    return random_rgba(colorCode);
};

const base64Encode = function (input: string): Base64String {
    return btoa(input);
};

const base64Decode = function (input: Base64String): string {
    return atob(input);
};

// Serialization: From functions

export const fromSerializedString = (value: string): any => {
    return base64Decode(value);
};
 
export const jsonFromSerializedString = (value: string): any => { 
    return JSON.parse(applyIfDefined(value, fromSerializedString)); 
};

// Serialization: To functions

export const toUrlEncodedSerializedString = (value: string): string => {
    return encodeURIComponent(base64Encode(value));
};

export const jsonToUrlEncodedSerializedString = (value: any): string => {
    return toUrlEncodedSerializedString(JSON.stringify(value, null, 1).trim());
};

export const jsonToSerializedString = (value: any): string => {
    return base64Encode(JSON.stringify(value, null, 1).trim());
};

