import React from "react";
import NumericInput from "react-numeric-input";
import { isNumber, isNull, isUndefined } from "lodash";

type ReactNumberCellEditorProps = {
    value: number;
    colDef: any;
};
type ReactNumberCellEditorState = {
    value: number;
};

/**
 * Market data table.
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @description Custom numeric cell for ag-grid third-party library.
 * @see https://codesandbox.io/s/jovial-sunset-uq0bk?file=/src/app/AgGrid.js
 */
class ReactNumberCellEditor extends React.Component<
    ReactNumberCellEditorProps,
    ReactNumberCellEditorState
> {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {}

    handleCreateOption = (inputValue) => {};

    handleChange(value, rawInput) { 
        // Handling issue with decimals and negative numbers
        if(rawInput !== '-' && (rawInput.substr(rawInput.length - 1) !== '.')){
            this.setState({
                value: value,
            });
        }
    }

    isPopup(): boolean {
        return false;
    }

    setValue(): number {
        return this.state.value;
    }

    getErrorMessage(cellValue): string | undefined {
        const coldDef = this.props.colDef,
            minValue = coldDef.valueMin,
            maxValue = coldDef.valueMax,
            isMinError = isNumber(minValue) ? cellValue < minValue : false,
            isMaxError = isNumber(maxValue) ? cellValue > maxValue : false;
        if (isNaN(cellValue) || isNull(cellValue) || isUndefined(cellValue)) {
            return `Error: Non numeric value: ${cellValue}.`;
        }
        if (isMinError && isMaxError) {
            return `Input number must be greater than  or equal to  ${minValue}  and less than or equal to ${maxValue}.`;
        }
        if (isMaxError) {
            return `Input number must be less than or equal to ${maxValue}.`;
        }
        if (isMinError) {
            return `Input number must be greater than or equal to ${minValue}. `;
        }
        return undefined;
    }

    getValue(): number {
        const newState = this.state.value,
            oldState = this.props.value,
            errorMessage = this.getErrorMessage(this.state.value); 
        if (!errorMessage) {
            return newState;
        }
        alert(errorMessage);
        return oldState;
    }

    render() {
        return (
            <NumericInput
                onChange={this.handleChange}
                value={this.state.value}
            />
        );
    }
}

export default ReactNumberCellEditor;
