import { FactoryServiceInterface } from "../../../../definitions";
import {MarketData} from "../../models/ProductMarketData";

// Generic dictionary
export type Source = { [key: string]: any}
export type Return = MarketData

/**
 * Factory service Product Market from raw data.
 * 
 * @author Nick Odumo <nick@sharkbyte.ca>
 */
export class ProductMarketDataFactoryServiceDictionaryImplementation
    implements FactoryServiceInterface<Source, Return> {

    create(source: Source): Return {
        return {
            productName: source.productName,
            marketSize: source.marketSize,
            growthRate: source.growthRate,
            marketShare: source.marketShare,
            marketShareCompetitor: source.marketShareCompetitor,
            sustainabilityRating: source.sustainabilityRating,
        };
    }
}

export const productMarketDataFactoryServiceDictionary: ProductMarketDataFactoryServiceDictionaryImplementation = 
    new ProductMarketDataFactoryServiceDictionaryImplementation();
