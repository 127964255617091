import "./internal.scss";
import * as React from "react";

export default function ApplicationFooter() {
    return ( 
        <footer className="footer">  
            <div className="col-sm-3 hidden-md-down">© 2020 IIBD Global Ltd.</div> 
            <div className="col-sm-9 text-right">  
                <span className=""> 
                    Learn more at  <a href="https://www.iibd.com">IIBD.com </a> 
                    and 
                    <a href="https://www.sabresim.com"> sabresim.com</a>
                </span>
            </div> 
        </footer> 
    );
}
