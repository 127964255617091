import "./internal.scss";
import * as React from "react";
import {Navbar, Nav} from "react-bootstrap";

/**
 * Navigation bar.
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @param props Navigation bar
 */
export default function NavigationBar(props : any) {
  return (
    <>
      <Navbar bg="dark" variant="dark">
        <Navbar.Brand href="#home">
          <img id="navbar-brand" alt=""
            src={
              require('./IIBD_WHITE.png')
            }
            width="47"
            height="28"
            className="d-inline-block align-top"/>
        </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto titleinheader">3D Portfolio Plotting Tool</Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}
