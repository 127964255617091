import * as React from "react";
import {useState} from "react";
import {useDoubleTap} from 'use-double-tap';
import {isString} from "lodash";
import {TableContext} from "../../contexts/TableContext";
import {arrayWhereMatch, isStringDefinedAndNoneEmpty} from "../../utils";
import {MarketDataTableColumnHeaderProps} from "./types";
import "./internal.scss";

/**
 * Market data-table column header.
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @param props Props
 */
export default function MarketDataTableColumnHeader(
    props: MarketDataTableColumnHeaderProps
) {
    const columnOverrides = arrayWhereMatch(
        props.marketDataTableCustomizations.columnOverrides,
        {columnFieldId: props.columnFieldId}
    );
    const [columnHeaderLabel, setColumnHeaderLabel] = useState(
        columnOverrides
            ? columnOverrides.columnHeaderLabel
            : props.columnHeaderLabel
    );
    const onInputValueChanged = function (e) {
            const newValue = e.target.value;
            if (isString(newValue)) {
                setColumnHeaderLabel(newValue);
                props.onHeaderOverrideDefined({
                    columnFieldId: props.columnFieldId,
                    newColumnHeaderLabel: newValue,
                });
            }
        },
        bindDoubleTapEditStart = useDoubleTap((event) => {
           
        }),
        bindDoubleTapEditEnd = useDoubleTap((event) => {
           
        });

    return (
        <TableContext.Consumer>
            {context => {
                const onInputTriggerEdit = (e) => {
                        if (isStringDefinedAndNoneEmpty(e.target.value)) {
                            context.setHeaderId('')
                        } else {
                            alert(`Header label "${e.target.value}" must not be empty!`);
                        }
                    },
                    onInputTriggerStopEdit = (e) => {
                        context.setHeaderId(props.columnFieldId)
                    };
                return context.headerId === props.columnFieldId ? (
                    <input
                        value={columnHeaderLabel}
                        onChange={onInputValueChanged}
                        onDoubleClick={onInputTriggerEdit}
                        onTouchEnd={onInputTriggerEdit}
                        {...bindDoubleTapEditEnd}
                    />
                ) : (
                    <div onTouchEnd={onInputTriggerStopEdit} onDoubleClick={onInputTriggerStopEdit} {...bindDoubleTapEditStart}>
                        {columnHeaderLabel}
                    </div>
                )
            }}
        </TableContext.Consumer>
    )
}
