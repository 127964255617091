import { ColumnDefinition, MarketDataTableCustomizations } from "./types";
import { HeaderOverrideDefinedEvent } from '../marketdatatablecolumnheader/types';
import {
    arrayUpdateWhereMatch
} from "../../utils";

// --- Private: utility functions

const defaultMarketDataTableCustomizations: MarketDataTableCustomizations = {
    columnOverrides: [],
};

/**
 * Parsed serialized column headers.
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @private marketdatatable
 * @param serializedMarketDataTableCustomizations Serialized values
 */
const parseMarketDataTableCustomizations = (
    serializedMarketDataTableCustomizations: any | undefined
): MarketDataTableCustomizations => {
    return serializedMarketDataTableCustomizations
        ? serializedMarketDataTableCustomizations
        : defaultMarketDataTableCustomizations;
};

/**
 * Update for a given column override.
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @param columnOverrides Column overrides
 * @param columnDefinition Column definition
 */
const updateColumnOverride = (
    columnOverrides: ColumnDefinition[],
    columnDefinition: ColumnDefinition
): ColumnDefinition[] => {
    return arrayUpdateWhereMatch(
        columnOverrides,
        { columnFieldId: columnDefinition.columnFieldId },
        columnDefinition
    );
};

const fromEventToColumnDefinition = (
    headerOverrideDefinedEvent: HeaderOverrideDefinedEvent 
): ColumnDefinition => {
    return { 
        columnFieldId: headerOverrideDefinedEvent.columnFieldId,
        columnHeaderLabel: headerOverrideDefinedEvent.newColumnHeaderLabel,
    };
};


 
// --- Public: utility functions

/**
 * Initial market data customizations.
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @param serializedMarketDataTableCustomizations Init market data table customizations
 */
export const initMarketDataTableCustomizations = (
    serializedMarketDataTableCustomizations: string | undefined
): MarketDataTableCustomizations => {
    return parseMarketDataTableCustomizations(
        serializedMarketDataTableCustomizations
    );
};

/**
 * Update for a given column override.
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @param marketDataTableCustomizations Data table defintions
 */
export const updateMarketDataTableCustomizations = (
    marketDataTableCustomizations: MarketDataTableCustomizations
) => {  
    return (
        headerOverrideDefinedEvent: HeaderOverrideDefinedEvent
    ): MarketDataTableCustomizations => {
        // Build upon overrides
        return {
            ...marketDataTableCustomizations,
            columnOverrides: updateColumnOverride(
                marketDataTableCustomizations.columnOverrides,
                fromEventToColumnDefinition(headerOverrideDefinedEvent)
            ),
        };
    };
};
