import * as React from "react";

import { MarketDatatableItemCountProps } from "./types";

/**
 * Market data table item count.
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @param props Props
 */
export default function MarketDatatableItemCount(
    props: MarketDatatableItemCountProps
) {
    return <span><b># of records:</b> {props.count}</span>;
}
