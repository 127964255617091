import * as React from "react";
import {useMemo} from "react";
import Plot from "react-plotly.js";
import sizeMe from 'react-sizeme'
import {MarketDataArray} from "../../domain/market/models/ProductMarketData";
import { Dimensions } from "../../utils";
import {
    calcLargestGrowthRate,
    calcLargestShareOfMarketSize,
    calcMarketPlotOverrides,
    calcSmallestGrowthRate
} from '../internal';
import {MarketPlotComputedRenderProps} from "../types";
import {compPlotlyLayout, productMarketDataToMarker} from "./internal";
import {Market2DPlotProps} from "./types";


/**
 * Market graph 2D bubble plot.
 *
 * @version 0.0.1
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @see https://plotly.com/javascript/bubble-charts/
 * @param props Market 2D Graph props
 */
export const Market2DPlot = sizeMe({ monitorHeight: true, monitorWidth: true, refreshRate: 16})(function Market2DPlot(props: Market2DPlotProps) {
    const marketData: MarketDataArray = props.data,
        computedRenderProps = (): MarketPlotComputedRenderProps => {
            return {
                largestShareOfMarketSize: calcLargestShareOfMarketSize(
                    marketData
                ),
                largestGrowthRate: calcLargestGrowthRate(marketData),
                smallestGrowthRate: calcSmallestGrowthRate(marketData)
            };
        },
        market3DComputedRenderProps: MarketPlotComputedRenderProps = useMemo(
            computedRenderProps,
            [marketData]
        ); 
    return (
        <div className={props.className}>
            <Plot
                data={props.data.map(
                    productMarketDataToMarker(market3DComputedRenderProps)
                )}
                layout={compPlotlyLayout(
                    props.size as Dimensions, 
                    calcMarketPlotOverrides(props.marketDataTableCustomizations),
                    market3DComputedRenderProps)
                }
            />
        </div>
    );
})

export default Market2DPlot;