import { range } from "lodash";
import {
    LABEL_BCG_PLOT_TITLE,
    LABEL_GROWTH_RATE,
    LABEL_MARKET_SHARE_RELATIVE,
    LABEL_SUSTAINABILITY
} from "../../constants";
import { MarketData } from "../../domain/market/models/ProductMarketData";
import { Dimensions } from "../../utils";
import { calcAdjustedSizeOfSphere, calcRangeOfYAxis, getPlotDimensions } from "../internal";
import { MarketDataPlotCustomizations, MarketPlotComputedRenderProps } from "../types";

// --- Constants 

/**
 * Calculate layout configuration.
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @param dimensionsContainer Dimensions
 * @param dimensionsWindow Dimensions window
 * @param plotCustomizations Plot customizations
 * @param marketPlotComputedRenderProps Market 3D computed render props
 */
export const plotlyLayoutConfig = (
    dimensionsContainer: Dimensions,
    plotCustomizations: MarketDataPlotCustomizations,
    marketPlotComputedRenderProps: MarketPlotComputedRenderProps) => {
    const zCalculatedRange = calcRangeOfYAxis(marketPlotComputedRenderProps)
    const zTicks = range(zCalculatedRange[0], zCalculatedRange[1], 5);
    const dimensions = getPlotDimensions(dimensionsContainer.height, dimensionsContainer.width);
    return {
        height: dimensions,
        width: dimensions,
        dimension: "ratio",
        aspectmode: 'manual',
        aspectratio: {
            x: 1,
            y: 1,
            z: 1,
        },
        autosize: false,
        showlegend: true,
        sizeref: 0.2,
        legenditem: {
            textfont: {
                color: "red",
            },
            marker: {
                size: 20,
            },
        },
        title: {
            text: LABEL_BCG_PLOT_TITLE,
            y: 0.9,
            x: 0.5,
            xanchor: "center",
            yanchor: "top",
        },
        marker: {
            symbol: "square",
            size: 1,
        },
        scene: {
            aspectmode: 'cube',
            dragmode: "turntable",
            dimension: "ratio",
            bccolor: 'rbg(116, 208, 241)',
            // zoom: 0.3,
            aspectratio: {
                x: 1,
                y: 1,
                z: 1,
            },
            xaxis: {
                type: "log",
                title: { text: LABEL_MARKET_SHARE_RELATIVE },
                zerolinecolor: "#30336b",
                zerolinewidth: 5,
                zeroline: true,
                tickvals: [10, 1, 0.1],
                nticks: 3,
                range: [-1, 1],
            },
            yaxis: {
                title: {
                    text: plotCustomizations.sustainabilityColumnLabel?.columnHeaderLabel
                        ? plotCustomizations.sustainabilityColumnLabel.columnHeaderLabel
                        : LABEL_SUSTAINABILITY,
                },
                showbackground: true,
                gridcolor: "rgb(222,223,224)",
                backgroundcolor: "rgb(247,247,247)",
                tick0: 0,
                nticks: 10,
                tickvals: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                range: [0, 10],
            },
            zaxis: {
                backgroundcolor: "rgb(247,247,247)",
                gridcolor: "rgb(222,223,224)",
                showbackground: true,
                title: {
                    text: plotCustomizations.growthRateColumnLabel?.columnHeaderLabel
                        ? plotCustomizations.growthRateColumnLabel.columnHeaderLabel
                        : LABEL_GROWTH_RATE,
                },
                tick0: 0,
                zerolinecolor: "rgb(78,91,108)",
                zerolinewidth: 5,
                range: zCalculatedRange,
                tickvals: zTicks
            },
            camera: {
                center: {},
                eye: {
                    x: 1,
                    y: 2.3,
                    z: 1.0
                },
                up: {
                    x: 1,
                    y: 1,
                    z: 1
                },
            }
        },
        yaxis: {
            zeroline: true
        },
        xaxis: {
            type: "log",
            zeroline: true,
        },
        legend: {
            itemsizing: "constant",
            y: 1,
            yanchor: "top",
            xanchor: "right"
        },
        margin: {
            l: 0,
            r: 0,
            b: 0,
            t: 50,
        },
    };
};

// --- Functions

const xLens = function (productMarketData: MarketData): number {
    const mySize = productMarketData.marketShare;
    const competitorSize = productMarketData.marketShareCompetitor;
    return Math.round(mySize / competitorSize * 100) / 100;
};

const yLens = function (productMarketData: MarketData): number {
    return productMarketData.sustainabilityRating;
};

const zLens = function (productMarketData: MarketData): number {
    return productMarketData.growthRate;
};

const sizeLens = function (
    dimensions: Dimensions,
    market3DComputedRenderProps: MarketPlotComputedRenderProps,
    productMarketData: MarketData
): number { 
    return calcAdjustedSizeOfSphere(
        market3DComputedRenderProps.largestShareOfMarketSize,
        dimensions.height / 10,
        productMarketData
    );
};

const toPlotlyMarker = function (
    dimensions: Dimensions,
    market3DComputedRenderProps: MarketPlotComputedRenderProps,
    productMarketData: MarketData,
    plotCustomizations: MarketDataPlotCustomizations,
): any {
    const labelX = LABEL_MARKET_SHARE_RELATIVE;
    const labelZ = plotCustomizations.growthRateColumnLabel?.columnHeaderLabel
        ? plotCustomizations.growthRateColumnLabel.columnHeaderLabel
        : LABEL_GROWTH_RATE;
    const labelY = plotCustomizations.sustainabilityColumnLabel?.columnHeaderLabel
        ? plotCustomizations.sustainabilityColumnLabel.columnHeaderLabel
        : LABEL_SUSTAINABILITY;

    // const a =    console.log(sizeLens(dimensions, market3DComputedRenderProps, productMarketData))
    //console.log(`${JSON.stringify(dimensions)}${a}`)
    return {
        name: productMarketData.productName,
        x: [xLens(productMarketData)],
        y: [yLens(productMarketData)],
        z: [zLens(productMarketData)],
        mode: "markers",
        text: "",
        hovermode: "closest",
        hoverlabel: {
            bgcolor: "#FFF",
            bordercolor: "#343a40"
        },
        hovertemplate:
            `${labelX}: %{x}<br>${labelZ}: %{z}<br>${labelY}: %{y}`,
        marker: {
            size: sizeLens(dimensions, market3DComputedRenderProps, productMarketData),
            gradient: {
                type: "vertical",
                color: ['#eee', '#000'],
            },
            symbol: "circle",
            line: {
                color: "rgb(204, 204, 204)",
                width: 2,
            },
            opacity: 0.7,
        },
        type: "scatter3d",
    };
};

export const productMarketDataToMarker = function (
    dimensions: Dimensions,
    market3DComputedRenderProps: MarketPlotComputedRenderProps,
    plotCustomizations: MarketDataPlotCustomizations,
) {
    const adjusteDimensions = { height: dimensions.height, width: dimensions.width }
    return (productMarketData: MarketData) =>
        toPlotlyMarker(adjusteDimensions, market3DComputedRenderProps, productMarketData, plotCustomizations);
};
