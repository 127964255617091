import {
    UIRouterReact,
    servicesPlugin,
    hashLocationPlugin,
    ReactStateDeclaration
} from "@uirouter/react";
import { states } from "./states";

const router = new UIRouterReact()

// --- Router plugin registration

router.plugin(servicesPlugin);
router.plugin(hashLocationPlugin); 

// --- Router state registry

states.forEach((state: ReactStateDeclaration) => {
    router.stateRegistry.register(state);
});

// --- Router default

router.urlService.rules.otherwise({ state: "root" });

export default router;
