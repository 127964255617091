export const APPLICATION_NAME = "application";

// --- Labels: Generic

export const LABEL_COPY_TEXT = "Text to copy"

// --- Labels: BCG

export const LABEL_BCG_PLOT_TITLE = "Growth Share Matrix"

export const LABEL_BCG_QUADRANT_CASH_COW = "Cash Cows"

export const LABEL_BCG_QUADRANT_DOG = "Dogs"

export const LABEL_BCG_QUADRANT_QUESTION_MARK = "Question Marks"

export const LABEL_BCG_QUADRANT_STAR = "Stars"

// --- Labels: Growth 

export const LABEL_GROWTH_RATE = "Growth Rate (%)"

export const LABEL_MARKET_SIZE = "Market Size"

export const LABEL_MARKET_SHARE = "Market Share (%)"

export const LABEL_MARKET_SHARE_COMPETITOR = "Market Share Competitor (%)"

export const LABEL_MARKET_SHARE_RELATIVE = "Relative Market Share"

export const LABEL_PRODUCT_NAME = "Product Name"

export const LABEL_SUSTAINABILITY = "SCA or Decile"

// --- MATH

export const MATH_PERCENT_MIN = 0;

export const MATH_PERCENT_MAX = 100;
 
// --- State names 

export const STATE_MARKET_SEGMENT_MODULE = "marketsegmentmodule";

export const STATE_ROOT = "application";

// --- 3RD-Party: AG grid themes

export const AG_GRID_CLASS = "application";
