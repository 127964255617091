

import { useState, useEffect } from 'react';
import {Dimensions} from "../utils";

const getHeight = () => window.innerHeight 
  || document.documentElement.clientHeight
  || document.body.clientHeight;

const getWidth = () => window.innerWidth 
  || document.documentElement.clientWidth 
  || document.body.clientWidth;

export const useCurrentWidthOfWindow = function (): Dimensions {
  let [height, setHeight] = useState(getHeight()); 
  let [width, setWidth] = useState(getWidth()); 
 
  useEffect(() => { 
    let timeoutId:  NodeJS.Timeout | null = null;
 
    const resizeListener = () => { 
      if(timeoutId){
        clearTimeout(timeoutId); 
      }
      timeoutId = setTimeout(() => {
        setHeight(getHeight())
        setWidth(getWidth())
      }, 150);
    };
    
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, [])

  return {height, width};
}