import "./internal.scss";
import * as React from "react";
import {Card, Form, FormControl} from "react-bootstrap";
import {CopyTextProps} from "./types";
import {LABEL_COPY_TEXT} from "../../constants";

/**
 * Copy text component.
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @param props Props
 */
export default function CopyText(props : CopyTextProps) {

  return (
    <div>
      <Card>
        <Card.Header>
          <Form.Label className="card-label secondary">Share or Save Your Portfolio Plot</Form.Label>
        </Card.Header>
        <Card.Body>
        
          <Form.Group controlId="copyPlotUrl">
          <Form.Text><i className="fa fa-link"> </i>Click on the link below. Press Ctrl-A to select all, then Ctrl-C to copy. Then save it or share it.</Form.Text>
          </Form.Group>
          <Form.Group>
          <FormControl className="text-muted compressed" 
              placeholder={LABEL_COPY_TEXT}
              aria-label={LABEL_COPY_TEXT}
              aria-describedby={LABEL_COPY_TEXT}
              value={
                props.text
              }
              onChange={
                (e : React.ChangeEvent < HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement >) => {}
              }/>
          </Form.Group>
          <Form.Text>
          <div className="">
            <p>
            <input type="checkbox" className=" "
              checked={
                props.isChecked
              }
              onChange={
                props.iEmbedded
              }/>As embedded plot without data table</p>            
          </div>
          </Form.Text>
          <Form.Group>
          <Form.Text>Privacy: Your data is in the link itself. We do not have any way to view or save your data.</Form.Text>
          </Form.Group>

        </Card.Body>
      </Card>
    </div>
  );
}
