import { FactoryServiceConstantInterface } from "../../../../definitions";
import { MarketData } from "../../models/ProductMarketData";

/**
 * Factory service Product Market blank default data.
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 */
export class ProductMarketDataFactoryServiceConstantImplementation
    implements FactoryServiceConstantInterface<MarketData> {

    randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }
    create(): MarketData {
        const share = this.randomIntFromInterval(20,90)
        return   {
            productName: "Product" +this.randomIntFromInterval(20,90),
            marketSize: this.randomIntFromInterval(300,900),
            growthRate: this.randomIntFromInterval(1,60),
            marketShare: share,
            marketShareCompetitor: (100 - share - 5),
            sustainabilityRating: this.randomIntFromInterval(1,9),
        };
    }

}

export const productMarketDataFactoryServiceConstant = 
    new ProductMarketDataFactoryServiceConstantImplementation()