import {useEffect, useState} from "react";

/**
 * Use current dimensions of ref element.
 *
 * @author Nick Odumo <nick@sharkbyte.ca>
 * @param elementRef React element ref
 */
export const useCurrentDimensionOfRef = (elementRef) => {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        const handleResize = (): void => {
            setWidth(elementRef.current.offsetWidth);
            setHeight(elementRef.current.offsetHeight);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [elementRef]);

    return { width, height };
};

